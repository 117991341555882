import {
  merchantHasAllowedStateRedirect,
  userIsAuthenticatedRedirect,
  userIsNotAuthenticatedRedirect,
} from 'auth'
import AppLayout from 'components/AppLayout'
import ExclusiveDealsTerms from 'public/ExclusiveDealsTerms'
import Privacy from 'public/Privacy'
import CcpaNotice from 'public/Privacy/ccpanotice'
import CcpaOptOut from 'public/Privacy/ccpaoptout'
import Previous from 'public/Privacy/previous'
import SettlementCredits from 'public/SettlementCredits'
import Terms from 'public/Terms'
import { ComponentType } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import OldDashboardHandler from 'scenes/OldDashboardHandler'
import ZendeskSSO from '../components/ZendeskSSO'
import Auth from './Auth'
import Billing from './Billing'
import CampaignCenter from './CampaignCenter'
import Cms from './Cms'
import Home from './Home'
import Integrations from './Integrations'
import Locations from './Locations'
import LookerEmbed from './LookerEmbed'
import Loyalty from './Loyalty'
import MerchantPicker from './Merchants/MerchantPicker'
import MerchantUsers from './MerchantUsers'
import CompleteInvitation from './MerchantUsers/Invitation/CompleteInvitation'
import InvitationSuccess from './MerchantUsers/Invitation/InvitationSuccess'
import OrderingManagement from './OrderingManagement'
import Points from './Points'
import Profile from './Profile'
import ChangePassword from './Profile/ChangePassword'
import RedeemManager from './RedeemManager'
import Reports from './Reports'
import Responses from './Responses'
import Unauthorized from './Status/Unauthorized'
import StoredValue from './StoredValue'
import ProviderExportWizard from './Subscribers/Export'
import SubscriberSetup from './Subscribers/Setup'
import SubscriberSetupOauth from './Subscribers/Setup/Oauth'
import Users from './Users'
import UserSupport from './UserSupport'

/**
 * Define all of the routes in our app. Since we're using HOC wrappers for
 * the route components (`userIsAuthenticatedRedirect` etc.), these have to
 * be defined statically. Otherwise the component will be rebuilt and
 * remounted on every render and lead to subtle bugs.
 */

type WrappedComponent<P = {}> = ComponentType<P>

function withLayout<P>(Component: WrappedComponent<P>) {
  return (props: P & { children?: React.ReactNode }) => (
    <AppLayout>
      <Component {...props} />
    </AppLayout>
  )
}

function authenticatedRoute<P>(Component: WrappedComponent<P>) {
  return userIsAuthenticatedRedirect(
    merchantHasAllowedStateRedirect(withLayout(Component))
  )
}

const RedirectToCampaigns = () => <Redirect to="/thanx_campaigns" />

// Routes that don't appear in the layout container
export const Routes = () => (
  <Switch>
    {/* Auth */}
    <Route path="/auth" component={userIsNotAuthenticatedRedirect(Auth)} />
    <Route
      exact
      path="/sso"
      component={userIsAuthenticatedRedirect(ZendeskSSO)}
    />
    <Route path="/sso/logout" component={() => <Redirect to="/" />} />

    <Route path="/choose" component={MerchantPicker} />

    {/* Legal Routes */}
    <Route path="/privacy/:handle?" component={Privacy} />
    <Route path="/privacy_v1" component={Previous} />
    <Route path="/ccpanotice" component={CcpaNotice} />
    <Route path="/ccpaoptout" component={CcpaOptOut} />
    <Route path="/terms/:handle?" component={Terms} />
    <Route exact path="/settlement_credits" component={SettlementCredits} />
    <Route
      exact
      path="/exclusive-deals-terms"
      component={ExclusiveDealsTerms}
    />

    {/* Authenticated routes */}
    <Route exact path="/" component={authenticatedRoute(Home)} />
    <Route path="/offers" component={RedirectToCampaigns} />
    {/* Fake route to handle opening a modal on navigation */}
    <Route
      path="/thanx_campaigns_m"
      component={() => <Redirect to="/thanx_campaigns?selected=message" />}
    />
    <Route
      path="/thanx_campaigns"
      component={authenticatedRoute(CampaignCenter)}
    />
    <Route path="/rewards" component={authenticatedRoute(RedeemManager)} />
    <Route path="/brand_content" component={authenticatedRoute(Cms)} />
    <Route
      path="/subscribers/initial_setup"
      render={() => <Redirect to="/subscribers/setup" />}
    />
    <Route
      path="/subscribers/setup"
      component={authenticatedRoute(SubscriberSetup)}
    />
    <Route
      path="/subscribers/export"
      component={authenticatedRoute(ProviderExportWizard)}
    />
    <Route
      path="/subscribers/oauth"
      component={authenticatedRoute(SubscriberSetupOauth)}
    />
    <Route path="/program_reviews/:id" render={() => <Redirect to="/" />} />
    <Route path="/loyalty" component={authenticatedRoute(Loyalty)} />
    <Route path="/reports" component={authenticatedRoute(Reports)} />
    <Route path="/points" component={authenticatedRoute(Points)} />
    <Route
      path="/ordering_management"
      component={authenticatedRoute(OrderingManagement)}
    />
    <Route path="/stored_value" component={authenticatedRoute(StoredValue)} />
    <Route path="/profile" component={authenticatedRoute(Profile)} />
    <Route path="/billing" component={authenticatedRoute(Billing)} />
    <Route
      path="/change_password"
      component={authenticatedRoute(ChangePassword)}
    />
    <Route path="/invitation/success" component={InvitationSuccess} />
    <Route path="/invitation/:token" component={CompleteInvitation} />
    <Route path="/integrations" component={authenticatedRoute(Integrations)} />
    <Route path="/users" component={authenticatedRoute(Users)} />
    <Route path="/responses" component={authenticatedRoute(Responses)} />
    <Route path="/user_support" component={authenticatedRoute(UserSupport)} />
    <Route path="/locations" component={authenticatedRoute(Locations)} />
    <Route path="/unauthorized" component={withLayout(Unauthorized)} />
    <Route
      path="/merchant_users"
      component={authenticatedRoute(MerchantUsers)}
    />
    <Route
      path="/o/feedback"
      component={() => <Redirect to="/reports/feedback" />}
    />
    <Route path="/o/" component={withLayout(OldDashboardHandler)} strict />
    <Route path="/l/" component={withLayout(LookerEmbed)} strict />
  </Switch>
)
