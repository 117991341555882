import exclusiveDealsTerms from './exclusiveDealsTerms'
import settlementCredits from './settlementCredits'

export default {
  common: {
    legal_container: {
      copyright: '%{year} Thanx',
    },
  },
  exclusive_deals_terms: exclusiveDealsTerms,
  settlement_credits: settlementCredits,
}
